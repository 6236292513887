import {Box, Button, Typography} from "@mui/material";
import React from "react";
import pleshDark from "../theme/pleshDark";

const InstallaApp = () => {

    return (
        <Box mt={4} pb={2} px={4}>
            <Typography gutterBottom textAlign={'center'} variant={'h3'} color={'primary'}>
                Istruzioni Web App
            </Typography>
            {/*<Typography gutterBottom textAlign={'center'} variant={'h5'} color={'primary'}>
                Follow our event also on Viva Engage
            </Typography>*/}
            <Button variant={'contained'}
                    href={"https://storage.googleapis.com/2ndstage-public/V9BxddLXaQewZ54feTqc/RmDUAFq6yx22DMq6sGCm/Linee%20guida%20piattaforma-2.pdf"}
                    target={'_blank'}
                    fullWidth
                    sx={{
                        marginTop: '20%',
                        minHeight: '100px',
                        fontWeight: 'bold',
                        borderRadius: '18px 0',
                        background: pleshDark.palette.primary.main
                    }}
            >
                Scarica le linee guida
            </Button>
        </Box>
    )
}

export default InstallaApp