import React, {useEffect, useState} from "react";
import Question from "./Questions/Question";
import {useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import {Box, CircularProgress, Grow, Stack, Typography} from "@mui/material";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import LogoBox from "./LogoBox";
import {DisplayName} from "./DisplayName";
import {PinnedQuestions} from "./PinnedQuestions";
import ReactionsLive from "./ReactionsLive";
import CountdownTimerWrapper from "./CountdownTimer";
import pleshDark from "../theme/pleshDark";
import policy from "../assets/images/home-icon/policy.png"

export default function VoteContent({user, event, loading, userData, setUserData}) {
    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')
    const [pinned, pinnedLoading, pinnedError] = useCollection(firestore.collection('users/plesh/events/' + id + '/queue').where("pinned", "==", true), {})
    const url = 'users/plesh/events/'
    const [theme, themeLoading, themeError] = useDocument(firestore.collection('users/plesh/events/' + id + '/themes').doc(event?.data().theme))

    useEffect(() => {
        getIdPlaying()
    }, [])

    useEffect(() => {

    }, [userData]);

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e = s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }

    return (
        <Box>
            <Stack direction={"column"} alignContent={"center"} sx={{height: '80vh', py: 2}}>
                {event.data().logo &&
                    <LogoBox logo={event.data().logoUrl ?
                        event.data().logoUrl
                        : 'https://storage.googleapis.com/2ndstage-public/logos/logo-2nd.png'}
                    />}
                {event?.data()?.timer && !!event?.data()?.timer?.startedAt &&
                    <Grow in timeout={2000}>
                        <Box display={'flex'} justifyContent={'center'}>
                            <CountdownTimerWrapper label={event.data().timer.label} initialTime={event.data().timer.duration} isLive={event.data().timer.startedAt}
                                                   startedAt={event.data().timer.startedAt}/>

                        </Box>
                    </Grow>}
                <Stack justifyContent={{xs: "center", sm: "flex-start"}} sx={{height: '100%', pt: 2}}>
                    {/*<Box mb={2} py={2} px={1}
                         borderRadius={"0 18px"}
                         style={{background: pleshDark.palette.primary.main, fontWeight: 'bold'}}>
                        <Typography variant={'h4'} fontStyle={'italic'}>
                            Costa Navarino
                        </Typography>
                        <Typography variant={'h6'} fontStyle={'italic'}>
                            26th-27th September 2024
                        </Typography>
                    </Box>*/}
                    {
                        event.data().autenticazione &&
                        <DisplayName userData={userData} setUserData={setUserData}/>
                    }
                    {/*event.data().agenda && <Box>
                        <Typography sx={{mx: 2}} component={"div"} variant={"h5"} fontWeight={"bold"}
                                    style={{
                                        borderBottom: '1px solid' + pleshDark.palette.accent.main,
                                        color: pleshDark.palette.accent.main
                                    }}>
                            {event.data().titoloAgenda}
                        </Typography>
                        <Agenda event={event}/>
                    </Box>*/}
                    {!pinnedLoading && !pinnedError && pinned ?
                        event.data().autenticazione
                            ?
                            (userData.displayName !== '' && userData.displayName) &&
                            <PinnedQuestions questions={pinned} event={event}/>
                            :
                            <PinnedQuestions questions={pinned} event={event}/>
                        :
                        <></>
                    }
                </Stack>
                {loading ?
                    <Box sx={{textAlign: 'center'}}>
                        <CircularProgress/>
                    </Box>
                    :
                    event.data().applausometro ?
                        event.data().autenticazione
                            ?
                            (userData.displayName !== '' && userData.displayName) &&
                            <ReactionsLive url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying}
                                           user={user}/>
                            :
                            <ReactionsLive url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying}
                                           user={user}/>
                        :
                        <></>
                }
                {(theme && !themeLoading && !themeError && !loading) ?
                    event.data().autenticazione
                        ?
                        (userData.displayName !== '' && userData.displayName) &&
                        <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                        :
                        <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                    :
                    <></>
                }
                {
                    !event.data().applausometro &&
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}
                           position={'absolute'} bottom={!event.data().applausometro ? 18 : 2} right={0} left={0}>
                        <img src={policy} width={'18px'}/>
                        <Typography variant={'caption'} fontWeight={'bold'}>
                            <a href={"/assets/Linee_Guida_Evento_1612.pdf"} target={'_blank'} style={{color: pleshDark.palette.accent.main}}>
                                Social Media policy
                            </a>
                        </Typography>
                    </Stack>
                }
            </Stack>
        </Box>

    )
}
