import React, {useEffect, useState} from "react";
import {Box, Dialog, Grid, IconButton, Stack, Typography} from "@mui/material";
import {QuestionSwitch} from "./Questions/QuestionSwitch";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {Close, Directions, DirectionsOutlined, InstallMobileOutlined} from "@mui/icons-material";
import {homeButtons} from "../config";
import pleshDark from "../theme/pleshDark";
import questionType from "../assets/data/typeMap";
import {find} from "lodash";
import DialogLayout from "../layout/DialogLayout";

export const PinnedQuestions = ({questions, event}) => {
    const [selected, setSelected] = useState('')
    const {id} = useParams()

    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(() => {
        selected !== '' ? getElementPlaying() : setElementPlaying({})
    }, [selected]);

    function getElementPlaying() {
        const selectedObj = find(homeButtons, ['id',selected])
        if(selected && !selectedObj) {
            firestore.collection('users/plesh/events/' + id + '/queue').doc(selected).onSnapshot(s => {
                setElementPlaying({...s.data(), id: s.id})
                return s.data()
            })
        } else {
            setElementPlaying({...selectedObj})
        }
    }

    return (
        <Stack mx={4}>
            {selected !== '' &&
                <Dialog variant={"gallery"} open={selected !== ''} onClose={() => setSelected('')} maxWidth={'md'} fullScreen={true}>
                    <DialogLayout title={elementPlaying.domanda || /*elementPlaying?.pageTitle ||*/ ''} id={elementPlaying?.id}>
                        <Box sx={{textAlign: 'right', position: 'fixed', top: 40, right: 5, zIndex: 1000}}>
                            <IconButton sx={{color: elementPlaying?.id !== 'gallery' ? pleshDark.palette.accent.main : "white",}}
                                        onClick={() => setSelected('')}>
                                <Close/>
                            </IconButton>
                        </Box>
                        {
                            <Box mt={4} px={4}>
                                <Typography gutterBottom textAlign={'center'} variant={'h3'} color={'primary'}>
                                    {elementPlaying?.domanda}
                                </Typography>
                            </Box>
                        }
                        <QuestionSwitch elementPlaying={elementPlaying} event={event}/>
                    </DialogLayout>
                </Dialog>}
            <Grid container justifyContent={'center'} width={'100%'} minHeight={'200px'} /* spacing={1}*/>
                {homeButtons.concat(questions.docs.sort((a, b) => a.data().position - b.data().position))
                    .map((button) => (
                        <Grid key={button.id} item xs={4} sx={{cursor: 'pointer'}}
                              onClick={() => setSelected(button.id)}
                        >
                            <Box mb={1} mx={0.5} position={'relative'}>
                                <Box sx={{background: 'white', width: '100%',}} minHeight={'90px'}
                                     border={'2px solid '+pleshDark.palette.primary.main}
                                     borderRadius={'0 18px'}>
                                    <Box sx={{position: 'absolute', top: !!button.img ? '10%' : '10%', left: 0, right: 0}}>
                                        {button.img || button.icon || questionType[button.data().tipo].img ||
                                            (button.id === 'JEKERVzbWhdCYKopVJ1C' ?
                                                <DirectionsOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />
                                                : questionType[button.data().tipo].icon)}
                                    </Box>
                                </Box>
                                <Box sx={{
                                    background: pleshDark.palette.accent.main,
                                    width: '100%',
                                    position: 'absolute',
                                    bottom: 0
                                }}
                                     minHeight={'25px'}
                                     borderRadius={'0 18px'}>
                                    <Typography variant={'caption'} fontStyle={'italic'} fontWeight={'bold'}
                                                textTransform={'none'} color={'white'}>
                                        {button.label || button?.data()?.domanda || ''}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}

            </Grid>
        </Stack>
    )
}