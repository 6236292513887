import {Box, Stack, Typography} from "@mui/material";
import pleshDark from "../theme/pleshDark";
import React from "react";
import header from "../assets/images/logo-bolton.png"

const DialogLayout = ({type, title, id, children}) => {
    console.log("id:",id)
    return (
        <Stack>
            {
                id !== 'gallery' ?
                    <>
                        <Box display={'flex'} justifyContent={'center'}
                             pb={2} px={4}
                             sx={{
                                 zIndex: 1000,
                                 height: '80px',
                                 background: 'white',
                                 position: 'fixed', top: 0, left: 0, right: 40,
                                 borderRadius: '0 0 24px 0'
                             }}>
                            <img src={header} height={'60px'}
                                 style={{marginTop: '8px', objectFit: 'cover', position: 'fixed'}}/>
                        </Box>
                        <Box mt={4}>
                            <Box sx={{
                                position: 'absolute', top: !!type ? '130px' : '73px', left: 0, right: 0
                            }}>
                                <Typography gutterBottom variant={'h5'} color={'primary'} fontStyle={'italic'} fontWeight={'bold'}
                                            textAlign={'center'}
                                >
                                    {(type === 'poll' || type === 'quiz') ? "Make your choice" : ""}
                                </Typography>
                                {(((type === 'poll' || type === 'quiz' || type === 'wordcloud'))) ?
                                    <Box margin={'auto'} mx={'2rem'} p={3} sx={{
                                        background: pleshDark.palette.accent.main,
                                        borderRadius: '0 32px',
                                        minHeight: '120px'
                                    }}>
                                        <Typography variant={"h4"} fontWeight={(type === 'poll' || type === 'quiz') ? 'bold' : 'inherit'}>
                                            {title}
                                        </Typography>
                                    </Box> : null}
                                {children}
                            </Box>
                        </Box>
                    </>
                    :
                    <>
                        <Box display={'flex'} justifyContent={'center'}
                             pb={2} px={4}
                             sx={{
                                 zIndex: 1000,
                                 height: '80px',
                                 background: pleshDark.palette.background.default,
                                 position: 'fixed', top: 0, left: 0, right: 0,
                             }}>
                            <img src={header} height={'45px'}
                                 style={{marginTop: '25px', objectFit: 'cover', position: 'fixed'}}/>
                        </Box>
                        <Box mt={4}>
                            <Box sx={{
                                position: 'absolute', top: !!type ? '130px' : '73px', left: 0, right: 0
                            }}>
                                <Typography gutterBottom variant={'h5'} color={'primary'} fontStyle={'italic'} fontWeight={'bold'}
                                            textAlign={'center'}
                                >
                                    {(type === 'poll' || type === 'quiz') ? "Make your choice" : ""}
                                </Typography>
                                {(((type === 'poll' || type === 'quiz' || type === 'wordcloud'))) ?
                                    <Box margin={'auto'} mx={'2rem'} p={3} sx={{
                                        background: pleshDark.palette.accent.main,
                                        borderRadius: '0 32px',
                                        minHeight: '120px'
                                    }}>
                                        <Typography variant={"h4"}>
                                            {title}
                                        </Typography>
                                    </Box> : null}
                                {children}
                            </Box>
                        </Box>
                    </>
            }

        </Stack>
    )
}

export default DialogLayout