import {Box, Button, Typography} from "@mui/material";
import React from "react";
import pleshDark from "../theme/pleshDark";

const PersolaArea = () => {

    return (
        <Box mt={5} pb={2} px={4}>
            <Typography gutterBottom textAlign={'center'} variant={'h3'} color={'primary'}>
                Informazioni logistiche e QR Code
            </Typography>
            <br/>
            <Typography gutterBottom textAlign={'center'} variant={'h5'} color={'primary'}>
                Al momento dell’iscrizione hai ricevuto le informazioni logistiche e il Qr Code personale che ti
                permetterà di accedere all’evento.
                <br/>
                <br/>
                Se non l’hai salvato o hai bisogno di recuperare le informazioni clicca il pulsante qui sotto e
                inserisci i dati con cui ti sei iscritto (CF/email) per recuperare le tue informazioni.
            </Typography>
            <Button variant={'contained'}
                    href={"https://bethedifference-ita.it/personal-info"}
                    target={'_blank'}
                    fullWidth
                    sx={{
                        marginTop: '20%',
                        minHeight: '80px',
                        fontWeight: 'bold',
                        borderRadius: '18px 0',
                        background: pleshDark.palette.primary.main
                    }}
            >
                Recupera le informazioni
            </Button>
        </Box>
    )
}

export default PersolaArea