import {
    CloudOutlined,
    FlakyOutlined,
    InsertEmoticonOutlined,
    InstallMobileOutlined,
    MicOutlined,
    PanToolOutlined,
    PhotoOutlined,
    PollOutlined,
    QuestionAnswerOutlined,
    QuestionMarkOutlined,
    QuizOutlined,
    TouchAppOutlined
} from "@mui/icons-material";
import qa from "../images/home-icon/qa.png";

const questionType = {
    qna: {
        text: 'Q&A',
        longText: 'Ask a question',
        icon: <QuestionAnswerOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />
    },
    toolpress: {
        text: 'Toolpress',
        icon: <QuestionAnswerOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />,
        img: <img src={qa} width={'45px'}/>
    },
    quiz: {
        text: 'Quiz',
        icon: <QuizOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />
    },
    poll: {
        text: 'Sondaggio',
        icon: <PollOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />
    },
    wordcloud: {
        text: 'Wordcloud',
        icon: <CloudOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />
    },
    sentiment: {
        text: 'Sentiment',
        icon: <InsertEmoticonOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />
    },
    siNo: {
        text: 'Sí/No',
        icon: <FlakyOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />
    },
    clickTo:{
        text: 'Click To',
        icon: <TouchAppOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />
    },
    links:{
        text: 'Click To',
        icon: <InstallMobileOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />,
        /*img: <img src={playlist} width={'45px'}/>*/
    },
    sendPhoto:{
        text:"Invio foto",
        icon:<PhotoOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />
    },
    audio:{
        text:"Invio audio",
        icon:<MicOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />
    },
    reserveSpot: {
        text: "Prenota il tuo posto!",
        icon: <PanToolOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />
    },
    altro:{
        text:"Altro",
        icon:<QuestionMarkOutlined color={"accent"} fontSize={'large'} sx={{marginTop: "8px"}} />
    },
}

export default questionType