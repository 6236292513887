import {createTheme, responsiveFontSizes} from "@mui/material";
import {THEME_CONFIG} from "../config";

const {gradients, primary, secondary, accent, accent2, accent3, background, mode, text} = THEME_CONFIG

const pleshDark = responsiveFontSizes(createTheme({
    typography: {
      fontFamily: ['Poppins']
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    background: background,
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    background: '#8dbbe1',
                    //background: 'white',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    color: accent,
                    '& fieldset':{
                        borderWidth:'2px !important',
                        borderRadius:'0 1rem',
                        borderColor: accent,
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: accent
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: accent
                }
            }
        },
        MuiButton: {
            variants: [
                //siNo
                {
                    props: { variant:'siNo' },
                    style: {
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        border: 0,
                        color: "white !important",
                    },
                },
                {
                    props: {variant:'siNo', risposta: 'si'},
                    style:{
                        background: gradients.siNo[0],
                    },
                },
                {
                    props: {variant:'siNo', risposta: 'no'},
                    style:{
                        background: gradients.siNo[1],
                    },
                },
                //sentiment
                {
                    props: {variant:'sentiment'},
                    style:{
                        width: '100%',
                        height: '100%',
                        borderRadius: 10,
                        border: 0,
                        color: "white !important",
                    },
                },
                {
                    props: {variant:'sentiment', risposta: 'daccordo'},
                    style:{
                        background: gradients.sentiment[0],
                    },
                },
                {
                    props: {variant:'sentiment', risposta: 'nonDaccordo'},
                    style:{
                        background: gradients.sentiment[1],
                    },
                },
                {
                    props: {variant: 'default'},
                    style:{
                        //height: '100px',
                        //width:'30%',
                        //fontSize: '18px',
                        background: accent,
                        border:'none',
                        color: text,
                        borderRadius: '16px 0',
                        textTransform: 'none',
                        fontWeight: "bold",
                        marginLeft: 'auto'
                    }
                },
                {
                    props: {variant: 'clap'},
                    style:{
                        height: '100px',
                        width:'80%',
                        fontSize: '18px',
                        background: 'linear-gradient(90deg, '+gradients.applausometro[0]+' 0%, '+gradients.applausometro[1]+')',
                        border:'none',
                        color: '#f1ebe9',
                        borderRadius: '2rem',
                        borderWidth: '3px',
                    }
                },
                {
                    props: {variant: 'wordcloud'},
                    style:{
                        background: 'linear-gradient(90deg, '+gradients.wordcloud[0]+' 0%, '+gradients.wordcloud[1]+')',
                        fontSize: '18px',
                        border:'none',
                        fontWeight:'bold',
                        color: '#fff',
                        borderRadius: '2rem',width: '100%', height: '50px'
                    }
                },
                {
                    props: {variant: 'home-question'},
                    style:{
                        background: 'white',
                        border:'1px solid #eceff1',
                        color: primary,
                        borderRadius: '0 18px',
                        height: '75px',
                        width: '28%',
                    }
                },
                {
                    props: {variant: 'qna'},
                    style:{
                        background: 'linear-gradient(90deg, '+gradients.qna[0]+' 0%, '+gradients.qna[1]+')',
                        fontSize: '18px',
                        border:'none',
                        fontWeight:'bold',
                        color: '#000',
                        borderRadius: '2rem',width: '100%', height: '50px'
                    }
                }
            ]
        },
    },
    palette: {
        mode: mode,
        primary: {
            main: primary,
        },
        secondary: {
            main: secondary,
        },
        accent: {
            main: accent,
            secondary: accent2,
            secondary2: accent3,
        },
        text: {
            primary: text,
        },
        background:{
            default:background
        },
        error: {
            main: accent3
        }

    },
}));

export default pleshDark
